<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="23"
    viewBox="0 0 24 23"
  >
    <g transform="translate(0 1)">
      <g class="a" transform="translate(0 1)">
        <rect class="c" width="24" height="21" rx="4" />
        <rect class="d" x="1" y="1" width="22" height="19" rx="3" />
      </g>
      <path
        class="a"
        d="M3138.715,449h20"
        transform="translate(-3136.715 -440)"
      />
      <path
        class="b"
        d="M3144.542,432.438v5"
        transform="translate(-3137.542 -432.438)"
      />
      <path
        class="b"
        d="M3144.542,432.438v5"
        transform="translate(-3127.542 -432.438)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Calendar",
};
</script>

<style lang="scss" scoped>
.a,
.b,
.d {
  fill: none;
}
.a,
.b {
  stroke: #2d323c;
  stroke-width: 2px;
}
.b {
  stroke-linecap: round;
}
.c {
  stroke: none;
}
</style>