<script>
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  props: ["chartData"],
  mixins: [reactiveProp],
  data: () => ({
    options: {
      legend: {
        display: false,
      },
      tooltips: { enabled: false },
      hover: { mode: null },
      cutoutPercentage: 75,
      responsive: true,
      maintainAspectRatio: true,
    },
  }),

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
