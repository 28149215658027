<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="a">
        <rect class="a" width="24" height="24" />
      </clipPath>
    </defs>
    <g class="b">
      <g class="c">
        <circle class="e" cx="12" cy="12" r="12" />
        <circle class="a" cx="12" cy="12" r="11" />
      </g>
      <rect class="d" width="2" height="9" transform="translate(11 4)" />
      <rect class="d" width="7" height="2" transform="translate(6 11)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Time",
};
</script>

<style lang="scss" scoped>
.a,
.c {
  fill: none;
}
.b {
  clip-path: url(#a);
}
.c {
  stroke: #2d323c;
  stroke-width: 2px;
}
.d {
  fill: #2d323c;
}
.e {
  stroke: none;
}
</style>