<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.414"
    height="10.121"
    viewBox="0 0 17.414 10.121"
    :class="[color, direction]"
  >
    <path
      class="a"
      d="M0,0,8,8l8-8"
      transform="translate(16.707 9.414) rotate(180)"
    />
  </svg>
</template>

<script>
export default {
  name: "Chevron",
  props: ["color", "direction"],
};
</script>

<style lang="scss" scoped>
.a {
  fill: none;
  stroke: #2d323c;
  stroke-width: 2px;
}
svg {
  transition: all ease 0.3s;
}

.right {
  transform: rotate(180deg);
}
.up {
  transform: rotate(0deg);
}
.down {
  transform: rotate(-180deg);
}

.ocean {
  .a {
    stroke: #05b5bc;
  }
}
</style>