<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path class="a" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" />
    <line class="b" x2="10" transform="translate(7 12)" />
  </svg>
</template>

<script>
export default {
  name: "Remove"
};
</script>

<style lang="scss" scoped>
.a {
  fill: #ff0064;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
</style>