<template>
  <div class="dashboard-progress-container" v-if="loaded">
    <select v-model="dateSpan" class="date-span">
      <option value="7Days">Last 7 days</option>
      <option value="30Days">Last 30 days</option>
      <option value="3Months">Last 3 months</option>
      <option value="6Months">Last 6 months</option>
      <option value="12Months">Last 12 months</option>
      <option value="CUSTOM">Custom date range</option>
    </select>
    <div class="custom-date-range" v-if="dateSpan == 'CUSTOM'">
      <TjingDateTimePicker
        class="datepicker"
        :canBeCleared="false"
        :readOnly="false"
        :options="startDateOptions"
        :dateTime="startDate"
        @update="updateStartDate($event)"
      />
      <p class="to-date">to</p>
      <TjingDateTimePicker
        class="datepicker"
        :canBeCleared="false"
        :readOnly="false"
        :options="endDateOptions"
        :dateTime="endDate"
        @update="updateEndDate($event)"
      />
    </div>
    <TjingTextOptionToggle
      class="par-throw-toggle"
      :width="120"
      leftValue="Percent"
      rightValue="Whole"
      @left="showPercentage = true"
      @right="showPercentage = false"
    />

    <div class="charts-container">
      <div class="chart-wrapper">
        <p class="chart-title"><b>Aces</b></p>
        <div class="chart">
          <div class="chart-text">
            <p>
              <b v-if="showPercentage">{{ toPercentage(stats.aces.part) }}%</b>
              <b v-else>{{ stats.aces.count }}</b>
            </p>
          </div>
          <DashboardDoughnutChart
            :chartData="{
              datasets: [
                {
                  data: [
                    toPercentage(stats.aces.part),
                    100 - toPercentage(stats.aces.part),
                  ],
                  backgroundColor: ['#FFCA4C', '#FFF7E5'],
                  borderWidth: 0,
                },
              ],
            }"
          />
        </div>
      </div>
      <div class="chart-wrapper">
        <p class="chart-title"><b>Eagles</b></p>
        <div class="chart">
          <div class="chart-text">
            <p>
              <b v-if="showPercentage"
                >{{ toPercentage(stats.eagles.part) }}%</b
              >
              <b v-else>{{ stats.eagles.count }}</b>
            </p>
          </div>
          <DashboardDoughnutChart
            :chartData="{
              datasets: [
                {
                  data: [
                    toPercentage(stats.eagles.part),
                    100 - toPercentage(stats.eagles.part),
                  ],
                  backgroundColor: ['#05B5BC', '#E5F8F8'],
                  borderWidth: 0,
                },
              ],
            }"
          />
        </div>
      </div>
      <div class="chart-wrapper">
        <p class="chart-title"><b>Birdies</b></p>
        <div class="chart">
          <div class="chart-text">
            <p>
              <b v-if="showPercentage"
                >{{ toPercentage(stats.birdies.part) }}%</b
              >
              <b v-else>{{ stats.birdies.count }}</b>
            </p>
          </div>
          <DashboardDoughnutChart
            :chartData="{
              datasets: [
                {
                  data: [
                    toPercentage(stats.birdies.part),
                    100 - toPercentage(stats.birdies.part),
                  ],
                  backgroundColor: ['#4CDBB7', '#E5FAF5'],
                  borderWidth: 0,
                },
              ],
            }"
          />
        </div>
      </div>
      <div class="chart-wrapper">
        <p class="chart-title"><b>Pars</b></p>
        <div class="chart">
          <div class="chart-text">
            <p>
              <b v-if="showPercentage">{{ toPercentage(stats.pars.part) }}%</b>
              <b v-else>{{ stats.pars.count }}</b>
            </p>
          </div>
          <DashboardDoughnutChart
            :chartData="{
              datasets: [
                {
                  data: [
                    toPercentage(stats.pars.part),
                    100 - toPercentage(stats.pars.part),
                  ],
                  backgroundColor: ['#C1C1C4', '#F5F5F6'],
                  borderWidth: 0,
                },
              ],
            }"
          />
        </div>
      </div>
      <div class="chart-wrapper margin-top">
        <p class="chart-title"><b>Bogeys</b></p>
        <div class="chart">
          <div class="chart-text">
            <p>
              <b v-if="showPercentage"
                >{{ toPercentage(stats.bogeys.part) }}%</b
              >
              <b v-else>{{ stats.bogeys.count }}</b>
            </p>
          </div>
          <DashboardDoughnutChart
            :chartData="{
              datasets: [
                {
                  data: [
                    toPercentage(stats.bogeys.part),
                    100 - toPercentage(stats.bogeys.part),
                  ],
                  backgroundColor: ['#FF7FB1', '#FFE5EF'],
                  borderWidth: 0,
                },
              ],
            }"
          />
        </div>
      </div>
      <div class="chart-wrapper margin-top">
        <p class="chart-title"><b>Double B</b></p>
        <div class="chart">
          <div class="chart-text">
            <p>
              <b v-if="showPercentage"
                >{{ toPercentage(stats.doubleBogeys.part) }}%</b
              >
              <b v-else>{{ stats.doubleBogeys.count }}</b>
            </p>
          </div>
          <DashboardDoughnutChart
            :chartData="{
              datasets: [
                {
                  data: [
                    toPercentage(stats.doubleBogeys.part),
                    100 - toPercentage(stats.doubleBogeys.part),
                  ],
                  backgroundColor: ['#FF599A', '#FFE5EF'],
                  borderWidth: 0,
                },
              ],
            }"
          />
        </div>
      </div>
      <div class="chart-wrapper margin-top">
        <p class="chart-title"><b>Triple B +</b></p>
        <div class="chart">
          <div class="chart-text">
            <p>
              <b v-if="showPercentage"
                >{{ toPercentage(stats.trippleBogeysAbove.part) }}%</b
              >
              <b v-else>{{ stats.trippleBogeysAbove.count }}</b>
            </p>
          </div>
          <DashboardDoughnutChart
            :chartData="{
              datasets: [
                {
                  data: [
                    toPercentage(stats.trippleBogeysAbove.part),
                    100 - toPercentage(stats.trippleBogeysAbove.part),
                  ],
                  backgroundColor: ['#FF3383', '#FFE5EF'],
                  borderWidth: 0,
                },
              ],
            }"
          />
        </div>
      </div>
      <div class="chart-wrapper"></div>
    </div>
    <div class="precision-stats-container">
      <div class="stat">
        <div class="title">
          <p><b>Circle hits </b></p>
          <p v-if="showPercentage">
            <b>{{ toPercentage(stats.circleHits.part) }}</b
            >%
          </p>
          <p v-else>
            <b>{{ stats.circleHits.count }}</b>
          </p>
        </div>

        <div class="bar">
          <div
            class="overlay"
            :class="{ full: stats.circleHits.part == 1 }"
            :style="{ width: toPercentage(stats.circleHits.part) + '%' }"
          ></div>
        </div>
      </div>
      <div class="stat mtop">
        <div class="title">
          <p><b>Conversions </b></p>
          <p v-if="showPercentage">
            <b>{{ toPercentage(stats.circleHitConversions.part) }}</b
            >%
          </p>
          <p v-else>
            <b>{{ stats.circleHitConversions.count }}</b>
          </p>
        </div>

        <div class="bar">
          <div
            class="overlay"
            :class="{ full: stats.circleHitConversions.part == 1 }"
            :style="{
              width: toPercentage(stats.circleHitConversions.part) + '%',
            }"
          ></div>
        </div>
      </div>
      <div class="stat mtop">
        <div class="title">
          <p><b>Inside putts </b></p>
          <p v-if="showPercentage">
            <b>{{ toPercentage(stats.insidePutts.part) }}</b
            >%
          </p>
          <p v-else>
            <b>{{ stats.insidePutts.count }}</b>
          </p>
        </div>

        <div class="bar">
          <div
            class="overlay"
            :class="{ full: stats.insidePutts.part == 1 }"
            :style="{ width: toPercentage(stats.insidePutts.part) + '%' }"
          ></div>
        </div>
      </div>
      <div class="precision-stats-footer">
        <div
          class="outside-putts"
          :class="{ filled: stats.outsidePutts.count > 0 }"
        >
          <p><b>Outside putts</b></p>
          <span
            ><p>
              <b>{{ stats.outsidePutts.count }}</b>
            </p>
          </span>
        </div>
        <div class="out-of-bounds-section">
          <p><b>Out of bounds</b></p>
          <div
            class="hex-wrapper"
            :class="{ red: stats.outOfBounds.count > 0 }"
          >
            <div class="hex-top"></div>
            <div class="hex-center">
              <p>
                <b>{{ stats.outOfBounds.count }}</b>
              </p>
            </div>
            <div class="hex-bottom"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DashboardDoughnutChart from "@/components/charts/DashboardDoughnutChart";
import TjingDateTimePicker from "@/components/TjingDateTimePicker";
import TjingTextOptionToggle from "@/components/UIElements/TjingTextOptionToggle";

export default {
  name: "DashboardProgress",
  components: {
    DashboardDoughnutChart,
    TjingDateTimePicker,
    TjingTextOptionToggle,
  },
  data() {
    return {
      startDate: moment().subtract(30, "days"),
      endDate: moment().endOf("day"),
      stats: null,
      loaded: false,
      dateSpan: "30Days",
      showPercentage: true,
    };
  },
  watch: {
    dateSpan: function (newVal) {
      if (newVal == "7Days") {
        this.startDate = moment().subtract(7, "days").startOf("day");
        this.endDate = moment().endOf("day");
      }
      if (newVal == "30Days") {
        this.startDate = moment().subtract(30, "days").startOf("day");
        this.endDate = moment().endOf("day");
      }
      if (newVal == "3Months") {
        this.startDate = moment().subtract(3, "months").startOf("day");
        this.endDate = moment().endOf("day");
      }
      if (newVal == "6Months") {
        this.startDate = moment().subtract(6, "months").startOf("day");
        this.endDate = moment().endOf("day");
      }
      if (newVal == "12Months") {
        this.startDate = moment().subtract(12, "months").startOf("day");
        this.endDate = moment().endOf("day");
      }

      this.loadData();
    },
  },
  computed: {
    endDateOptions() {
      return {
        minDateTime: this.startDate,
        maxDateTime: moment().add(1, "days").startOf("day"),
        timeOnly: false,
        dateOnly: true,
        mode: "standard",
      };
    },
    startDateOptions() {
      return {
        minDateTime: moment().subtract(5, "years"),
        maxDateTime: this.endDate,
        timeOnly: false,
        dateOnly: true,
        mode: "standard",
      };
    },
  },
  methods: {
    updateStartDate(date) {
      this.startDate = date;
      this.loadData();
    },
    updateEndDate(date) {
      this.endDate = date;
      this.loadData();
    },
    toPercentage(part) {
      return parseInt((part * 100).toFixed(0));
    },
    async loadData() {
      const userStats = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
              {
              userStats(userId:"${this.$store.state.user.sessionInfo.userId}",
              startDate:"${this.startDate.toISOString()}",
              endDate:"${this.endDate.toISOString()}"){
                aces{
                  count
                  part
                }
                circleHits{
                  count
                  part
                }
                circleHitConversions{
                  count
                  part
                }
                insidePutts{
                  count
                  part
                }
                outsidePutts{
                  count
                  part
                }
                outOfBounds{
                  count
                  part
                }
                eagles{
                  count
                  part
                }
                birdies{
                  count
                  part
                }
                pars{
                  count
                  part
                }
                bogeys{
                  count
                  part
                }
                doubleBogeys{
                  count
                  part
                }
                trippleBogeysAbove{
                  count
                  part
                }
              }
            }
          `,
        },
      });

      this.stats = userStats.data.data.userStats;
      this.loaded = true;
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.par-throw-toggle {
  margin-left: auto;
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}
.hex-wrapper {
  margin-left: 8px;
  &.red {
    .hex-center {
      background: $dusk;
      color: white;
    }
    .hex-top {
      border-right: 8px solid $dusk;
    }
    .hex-bottom {
      border-left: 8px solid $dusk;
    }
  }
  .hex-center {
    float: left;
    width: 16px;
    height: 28px;
    background-color: $cloud;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hex-top {
    float: left;
    border-right: 8px solid $cloud;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
  }
  .hex-bottom {
    float: left;
    border-left: 8px solid $cloud;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
  }
}

.precision-stats-container {
  margin-top: 24px;
  border: 1px solid $sleet;
  border-radius: 10px;
  padding: 18px 16px 22px 16px;
}
.precision-stats-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.outside-putts,
.out-of-bounds-section {
  display: flex;
  align-items: center;
}

.out-of-bounds-section {
  .out-of-bounds {
    position: relative;
    width: 30px;
    height: 17.32px;
    background-color: $fog;
    margin: 8.66px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    color: $midnight;
    transform: rotate(30deg);
    p {
      transform: rotate(-30deg);
    }
    &.red {
      background: $dusk;
      color: white;

      &:before {
        border-bottom: 8.66px solid $dusk;
      }
      &.out-of-bounds:after {
        border-top: 8.66px solid $dusk;
      }
    }
  }

  .out-of-bounds:before,
  .out-of-bounds:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }

  .out-of-bounds:before {
    bottom: 100%;
    border-bottom: 8.66px solid $fog;
  }

  .out-of-bounds:after {
    top: 100%;
    width: 0;
    border-top: 8.66px solid $fog;
  }
}

.outside-putts {
  span {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $cloud;
  }

  &.filled {
    span {
      color: white;
      background: $midnight;
    }
  }
}

.stat {
  &.mtop {
    margin-top: 18px;
  }
  .title {
    display: flex;
    justify-content: space-between;
  }
  .bar {
    margin-top: 10px;
    background: $cloud;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    position: relative;

    .overlay {
      transition: all ease 0.3s;
      background: linear-gradient(90deg, $sunrise 0%, $dusk 100%);
      height: 8px;
      border-radius: 4px;
      &.full {
        background: $twilight;
      }
    }
  }
}
.custom-date-range {
  margin-top: 24px;
}
.to-date {
  text-align: center;
  margin: 12px 0;
}

.date-span {
  margin: 32px 0 0px 0;
}

.dashboard-progress-container {
  padding: 0 12px;
}
.charts-container {
  display: flex;
  padding: 18px 16px 22px 16px;
  border-radius: 10px;
  border: 1px solid $sleet;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
}

.chart-title {
  margin-bottom: 10px;
  font-size: 0.75rem;
}
.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  padding: 0 5px;

  &.margin-top {
    margin-top: 24px;
  }

  .chart {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    .chart-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
  .chart-wrapper {
    .chart {
      .chart-text {
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .chart-title {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .chart-wrapper {
    width: 14%;

    &.margin-top {
      margin-top: 0;
    }
  }
}

@media (min-width: 1200px) {
  .par-throw-toggle {
    margin-top: 0;
    width: auto;
  }

  .hex-wrapper {
    margin-left: 18px;
    &.red {
      .hex-top {
        border-right: 16px solid $dusk;
      }
      .hex-bottom {
        border-left: 16px solid $dusk;
      }
    }
    .hex-center {
      float: left;
      width: 32px;
      height: 56px;
      background-color: $cloud;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .hex-top {
      float: left;
      border-right: 16px solid $cloud;
      border-top: 28px solid transparent;
      border-bottom: 28px solid transparent;
    }
    .hex-bottom {
      float: left;
      border-left: 16px solid $cloud;
      border-top: 28px solid transparent;
      border-bottom: 28px solid transparent;
    }
  }
  .precision-stats-container {
    margin-top: 42px;
    padding: 40px 42px 48px 42px;
  }

  .precision-stats-footer {
    margin-top: 48px;
    p {
      font-size: 24px;
    }
  }
  .outside-putts {
    span {
      margin-left: 18px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
  }

  .stat {
    &.mtop {
      margin-top: 48px;
    }
    .title {
      p {
        font-size: 24px;
      }
    }

    .bar {
      margin-top: 24px;
      height: 16px;
      border-radius: 8px;

      .overlay {
        height: 16px;
        border-radius: 8px;
      }
    }
  }
  .dashboard-progress-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .charts-container,
  .precision-stats-container {
    width: 100%;
  }

  .charts-container {
    margin-top: 64px;
    padding: 40px;
  }

  .chart-title {
    font-size: 24px;
    margin-bottom: 28px;
  }

  .date-span {
    margin-top: 0;
    width: 250px;
    height: 50px;
  }

  .chart-wrapper {
    &:nth-child(n + 4) {
      margin: 0;
    }

    .chart {
      width: 120px;
      height: 120px;

      .chart-text {
        p {
          font-size: 24px;
        }
      }
    }
  }

  .custom-date-range {
    display: flex;
    margin: 0;
    margin-left: 48px;
    align-items: center;

    .datepicker {
      width: 300px;
    }
  }
  .to-date {
    font-size: 18px;
    margin: 0 16px 0 16px;
  }
}
</style>
